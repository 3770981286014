.player {
  height: 100%;
  position: relative;
  width: 100%;
}
@media (max-width: 700px) {
  .player {
    height: 75%;
  }
}

.player div[class^="configurator___"] {
  background: var(--body-background);
}
