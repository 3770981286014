:root {
  --border-radius-base: 2px;

  --grey-2: #fafafa;
  --grey-4: #e8e8e8;
  --primary-color: #78c4a2;

  --background-body: var(--grey-2);
  --light-border: 1px solid var(--grey-4);

  --padding-xs: 8px;
  --padding-sm: 12px;
  --padding-md: 16px;

  --header-height: 65px;
  --logo-height: calc(var(--header-height) - 2 * var(--padding-md));
  --side-padding: 5%;
}

body {
  background: var(--background-body);
}

.app {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.header {
  align-items: center;
  background: white;
  border-bottom: var(--light-border);
  display: flex;
  height: var(--header-height);
  left: 0;
  padding: 0 var(--side-padding);
  position: absolute;
  right: 0;
  top: 0;
}

.header .logo {
  height: var(--logo-height);
  width: auto;
}

.content {
  bottom: 0;
  left: 0;
  padding: var(--padding-md) var(--side-padding);
  position: absolute;
  right: 0;
  top: var(--header-height);
}
